.white-list {
    background-color: #000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 80px;
}

.white-list-text {
    font-size: 72px;
    color: #fff;
    margin: 0px;
    margin-bottom: 100px;
    font-family: "Archivo Black", sans-serif !important;
}

.white-list .paper-img {
    width: 1406px;
    max-width: 85%;
    height: auto;
}

@media (max-width: 1450px) {
    .white-list img {
        max-width: 85%;
    }
}



@media (max-width: 768px) {
    .white-list-text  {
        font-size: 50px;
        margin-bottom: 50px;
    }

    .white-list img {
        max-width: 85%;
    }

    .white-list-top-img {
        height: 100px!important;
    }
}

@media (max-width: 520px) {
    .white-list-text  {
        font-size: 33px;
        margin-bottom: 35px;
    }
}
