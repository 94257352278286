html {
  height: 100%;
  background-color: #000;
}

.app {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
}

#root, .app {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
}

.app {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

#root {
  height: 100%;
}

#root, .app {
  width: 100%;
}

.app-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 750px;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.app-info-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.app-social-wrapper {
  border: 1px solid #A392FA;
  border-radius: 30px;
  width: 125px;
  padding: 8px 16px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  height: 19px;
  width: 1px;
  background: #A392FA;
}

.reserved {
  border: 1px solid #A392FA;
  color: #A392FA;
  border-radius: 30px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  bottom: 16px;
  font-size: 12px;
}


@keyframes flyStar {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(100vw - 50px), calc(100vh - 50px));
  }
}

.star {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  rotate: 90%;
}

.fly {
  animation: flyStar 3s linear;
}

.whitePaperBtn {
  width: 304px;
  height: 60px;
  background-color: #CFC6FF;
  color: #000;
  font-weight: 600;
  font-size: 24px;
  border-radius: 28px;
  margin: 0 auto;
  cursor: pointer;
  border: 0;
  font-family: "Archivo", sans-serif !important;
}

@media (max-width: 680px) {
  .whitePaperBtn  {
    height: 40px;
    width: 250px;
  }
}



@media screen and (max-width: 450px) {
  .app-info-area > img {
    width: 250px!important;
  }
}