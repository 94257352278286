    .page-claim .main-button {
      margin-top: -50px;
    }
    
    .page-claim .content .bottom-nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #464C72;
      border-radius: 20px;
      padding: 8px;
    }
    
    .page-claim .content .bottom-nav a {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      margin: 5px 0;
      text-align: center;
      text-decoration: none;
      color: #565656;
      font-size: 10px;
      font-weight: light;
      border-right: 1px solid #D9E9C3;
      width: 100%;
    }
    
    .page-claim .content .bottom-nav a:last-child {
      border-right: none;
    }
    
    .page-claim .content .bottom-nav a h3 {
      margin: 0;
      font-size: 30px;
    }
  
    .claim-button-root {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      z-index: 100;
    }
  
    .claim-button-root::selection {
      background: transparent;
    }
  
    .claim-button-wrapper {
      position: relative;
      width: 310px;
      height: 310px;
      display: flex;
      justify-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  
    .claim-button-wrapper::selection {
      background: transparent;
    }
  
    .claim-button-progressbar {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scaleX(-1) rotate(-0deg);
      pointer-events: none;
    }
  
    .claim-button-progressbar::selection {
      background: transparent;
    }
  
    .claim-button {
      width: 263px;
      text-decoration: none;
      background: transparent;
      border: 0px solid transparent;
      overflow: hidden;
      padding: 4px;
    }
  
    .claim-button::selection {
      background: transparent;
    } 
  
    .claim-button img {
      width: 100%;
    }
  
    .claim-button img::selection {
      background: transparent;
    } 
  
    .claim-button.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  
    @keyframes flyAndFade {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(-120px) scale(2);
      }
    }
    
    .flyAndFade {
      color: #DFF2D8;
      animation: flyAndFade 1s forwards;
      pointer-events: none;
    }
  
    .claim-charger-count {
      display: flex;
      flex-direction: row;
    }
  
    .claim-charger-count-text {
      font-size: 14px!important;
      font-weight: 400px;
    }
  
    .claim-charger {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
  
    .disable-dbl-tap-zoom {
      touch-action: manipulation;
    }
  
    .claim-balance,
    .page-claim-leaderboard-link,
    .claim-charger,
    .bottom-nav,
    .page-claim-header,
    .page-claim-title {
      z-index: 10;
    }
  
 
