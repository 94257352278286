@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-star {
  position: absolute;
  width: 11px;
  height: 11px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: blink 4s infinite;
}
